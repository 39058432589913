$(document).ready(function(e) {
  var t = 1;
  $('.sort').each(function( index ) {
    tableId = this.id;
    if(!tableId)
    {
      tableId = "t"+t;
      this.id = tableId;
      t++;
    }

    abcRow = "";
    filterRows = {};
    $(this).find('th').each(function( index ) {
      $(this).attr('data-table',tableId);
      $(this).bind( "click", function() {
        updateSort($(this).attr('data-table'),index+1);
      });

      if($(this).hasClass('abc'))
      {
        abcRow = index+1;
      }
      if($(this).hasClass('filter'))
      {
        filterRows[index+1] = 1;
      }
    });

    $('<div class="sortfkt funktionen_'+tableId+'"><form id="funktionenForm_'+tableId+'" name="funktionenForm_'+tableId+'" onsubmit="return false"><div style="width:1px;height:1px;visibility:hidden"><input type="text" name="sort_'+tableId+'" id="sort_'+tableId+'"><input type="radio" name="sort-dir_'+tableId+'" class="sort-dir_'+tableId+'" value="up"><input type="radio" name="sort-dir_'+tableId+'" class="sort-dir_'+tableId+'" value="down"><input type="text" name="index_'+tableId+'" id="index_'+tableId+'" value=""></div></form></div>').insertBefore(this);

    makeFilterControl(tableId,filterRows);

    if(abcRow)
    {
      makeAbcControl(tableId,abcRow);
    }
  });

  $('.sortfkt select,.sortfkt input').on('change',function(){
    setHASH();
    if (!("onhashchange" in window))
    {
      update();
    }
  })

  $('.sort').each(function( index ) {
    $("#funktionenForm_"+this.id).deserialize(location.hash.replace(/#/,''));
  });

  update();
});
$(window).bind( 'hashchange', function(e) {
  $('.sort').each(function( index ) {
    $("#funktionenForm_"+this.id).deserialize(location.hash.replace(/#/,''));
  });
  update();
});

function setHASH()
{
  newHASH = "";
  $('.sort').each(function( index ) {
    newHASH += $('#funktionenForm_'+this.id).serialize()+"&";
  });
  location.hash=newHASH.replace(/&&+/g,"&").replace(/&$/g,"");
}

function update()
{
  str = window.location.hash.replace("#","");
  str = decodeURIComponent((str+'').replace(/\+/g, '%20'));

  var FORM = {};

  var help = str.split("&");
  for(var i = 0; i < help.length; i++)
  {
    key_table = help[i].split("=")[0]
    key = key_table.split("_")[0];
    table = key_table.split("_")[1];
    if(!FORM[table])
    {
      FORM[table] = {};
    }
    FORM[table][key] = help[i].split("=")[1];
    if(key.indexOf("f-") == 0)
    {
      if(!FORM[table]['filter'])
      {
        FORM[table]['filter'] = {};
      }
      FORM[table]['filter'][key] = help[i].split("=")[1];
    }
  }

  parameter = new Array();
  for(tableId in FORM)
  {
    parameter[0] = "";
    if(FORM[tableId]['filter'])
    {
      for(f in FORM[tableId]['filter'])
      {
        parameter[0] += f.split("f-")[1]+"||"+FORM[tableId]['filter'][f]+",,,";
      }
    }

    parameter[1] = FORM[tableId]['sort'];
    if(FORM[tableId]['sort'] && FORM[tableId]['sort-dir'])
    {
      parameter[1] = FORM[tableId]['sort']+"-"+FORM[tableId]['sort-dir'];
    }
    parameter[2] = FORM[tableId]['tsuche'];
    parameter[3] = FORM[tableId]['index'];

    $('#alphabet'+FORM[tableId]['index']+'_'+tableId).addClass('an');

    str = parameter.join("&");
    updateTable(tableId,parameter[0],parameter[1],parameter[3]);
  }
}

var oldParam = {};
function updateTable(tableId,param_filter,param_sort,param_index)
{
  if(oldParam[tableId] != param_filter+"-"+param_sort+"-"+param_index)
  {
    oldParam[tableId] = param_filter+"-"+param_sort+"-"+param_index;

    filter(tableId,param_filter);
    if (param_sort)
    {
      help = param_sort.split("-");
      order = help[1];
      param_sort = help[0];


      if($('#'+tableId+' thead th:nth-child('+param_sort+')').hasClass('num'))
      {
        $('#'+tableId).jssort( $('#'+tableId+' thead th:nth-child('+param_sort+')'), 'tbody tr', 'tbody td:nth-child('+param_sort+')', 'text', function(a,b){ return (a.s*1 < b.s*1) ? -1 : 1; }, order );
      }
      else if($('#'+tableId+' thead th:nth-child('+param_sort+')').hasClass('html'))
      {
        $('#'+tableId).jssort( $('#'+tableId+' thead th:nth-child('+param_sort+')'), 'tbody tr', 'tbody td:nth-child('+param_sort+')', 'html', '', order );
      }
      else
      {
        $('#'+tableId).jssort( $('#'+tableId+' thead th:nth-child('+param_sort+')'), 'tbody tr', 'tbody td:nth-child('+param_sort+')', 'text', '', order  );
      }
    }

    if (param_index)
    {
      indexfilter(tableId,param_index,'filter');
    }
  }
}

/************************** sort **************************/
function updateSort(tableId,row)
{
  $('#sort_'+tableId).val(row);
  if ($('#'+tableId+' thead th:nth-child('+row+')').hasClass('sort'))
  {
    $('#'+tableId+' thead th:nth-child('+row+')').addClass('reverse').removeClass('sort');
    $('.sort-dir_'+tableId+'[value="down"]').prop("checked",true);
  }
  else
  {
    $('#'+tableId+' thead th').removeClass('sort').removeClass('reverse');
    $('#'+tableId+' thead th:nth-child('+row+')').addClass('sort');
    $('.sort-dir_'+tableId+'[value="up"]').prop("checked",true);
  }

  setHASH();
  if (!("onhashchange" in window))
  {
    update();
  }
}
/************************** filter **************************/
function filter (tableId,str)
{
  $('#'+tableId+' tbody tr').each(function(){
    if( abfrage(str,$(this),''))
    {
      $(this).removeClass('aus')
    }
    else
    {
      $(this).addClass('aus')
    }
  })
}
function abfrage (str,tr,oder)
{
  if (oder)
  {
    var ret = false;
  }
  else
  {
    var ret = true;
  }
  parameterA = str.split(",,,");
  for (var i = 0;i < parameterA.length; i++)
  {
    optval = parameterA[i].split("||");
    if(optval[0])
    {
      if(tr.find('td').get() != "" && optval[1])
      {
        optval[1] = optval[1].replace(/\//g,"\\\/");
        var Ergebnis = tr.find(eval("'td:nth-child("+optval[0]+")'")).html().search(eval("/"+optval[1]+"/i"));
        if (Ergebnis == -1 && !oder)
        {
          ret = false;
        }
        if (Ergebnis != -1 && oder)
        {
          ret = true;
        }
      }
    }
  }
  return ret;
}

