// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
//Turbolinks.start()
ActiveStorage.start()




//= require jquery
//= require jquery-ui/ui/widget.js
//= require jquery-ui/ui/widgets/datepicker.js
//= require jquery-ui/ui/i18n/datepicker-de.js
//= require tinymce-jquery

//first.js lib.js slick.min.js global.js resp.js menu.js menu_1.js menu_v4.js  > scientec.js
require('jquery-ui');
require('../uhh/datepicker-de.js');
require('../uhh/scientec.js');
require('../uhh/eventform.js');
require('../uhh/jssort.js');
require('../uhh/uhh.js');
