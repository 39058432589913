import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/icons/default';
import 'tinymce/plugins/link';
import 'tinymce/plugins/paste';
import 'tinymce-i18n/langs/de.js';

import 'tinymce/skins/ui/oxide/content.min.css'

document.addEventListener('DOMContentLoaded', function() {
  $('.datepicker').datepicker({
    changeMonth: true,
    changeYear: true,
    showAnim: 'slideDown',
    minDate: -365,
    maxDate: +730
  });

 $("#event_active").on("change", function() {
  text="Veranstaltung freigegeben"
  if ($("#event_active").prop("checked")==true && $("#event_changes_description").val()=="") {
   $("#event_changes_description").val(text);
  }
  if ($("#event_active").prop("checked")==false && $("#event_changes_description").val()==text){
   $("#event_changes_description").val("");
  }
 });

 $("#nojavascript").hide();

 tinyMCE.init({
    selector: '#event_description_de, #event_description_en',
    plugins: "link paste",
    paste_as_text: true,
    language : "de",
    menubar:false,
    statusbar: true,
    skin: false,
    content_css: false,
    valid_elements: 'a[href|target=_blank|title],strong,br,p,h3,ul,li',
    toolbar: 'undo redo | styleselect | bold | italic | link | bullist',
    content_style:  "p{font-size: 13px}\n h3,p,ul > li {font-family: TheSansUHH, sans-serif}",
    style_formats: [
     {title: "Überschrift", format: 'h3'},
     {title: 'Absatz', format : 'p'},
    ],
    elementpath: false,
    branding: false,
    resize: true
 });
 $(".event_confirm_create").on("click", function(e) {
  e.preventDefault();
  $("form.new_event").submit();
 });
 $(".event_confirm_edit").on("click", function(e) {
  e.preventDefault();
  $("form.new_event input#event_save_without_preview").val('edit');
  $("form.new_event").submit();
 });
$(".event_confirm_update").on("click", function(e) {
 e.preventDefault();
 $("form.edit_event").submit();
});
$(".event_switch_preview").on("click", function(e) {
 e.preventDefault();
 if($(".preview_en").is('.unsichtbar')){
  $(".preview_de").addClass('unsichtbar')
  $(".preview_en").removeClass('unsichtbar')
 } else {
  $(".preview_en").addClass('unsichtbar')
  $(".preview_de").removeClass('unsichtbar')
 }
 //$(".event_switch_preview").addClass('unsichtbar')
});

 $('#veranstaltungsformular .org a.aufzu').on('click',function(e){
   e.preventDefault();
   var $a = $(e.currentTarget);
   var $org = $a.parent('.org');
   if($org.is('.auf')){ $org.removeClass('auf').addClass('zu') }
   else if($org.is('.zu')){ $org.removeClass('zu').addClass('auf') }
 });
 $("#veranstaltungsformular .ausgabe ul li input[type='checkbox']:checked").parents("li.org").addClass("auf").removeClass("zu");

 if ($(".confirmsavebuttons").length>0) {
  window.onbeforeunload = function() {return "Die Veranstaltung wurde noch nicht gespeichert! Wollen Sie diese Seite verlassen?";};
  $('a').click(function() { window.onbeforeunload = null; });
  $('form').submit(function() { window.onbeforeunload = null; });
 }


 document.querySelectorAll('[data-checkall]').forEach((elem) => {
  elem.addEventListener('click', (e) => {
   var filter=e.target.getAttribute('data-checkall');
   e.target.form.querySelectorAll(filter).forEach((form_elem) => {
    form_elem.checked=e.target.checked;
   })
  });
  
 });

 //document.querySelectorAll('input[name="event[virtual]"]').forEach(function(elem) {elem.addEventListener("change", function(){
 // if (document.querySelector('input[name="event[virtual]"]:checked').value=='true') {
 //   document.getElementById('virtual_true').style.display = 'none';
 //   let room = document.getElementById('event_venue_room');
 //   if (room.value == '') {
 //     room.value = 'Digital';
 //   }
 // } else {
 //   document.getElementById('virtual_true').style.display = 'block';
 // }
 //})});



});
