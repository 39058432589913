window.set_virtual_event = function set_virtual_event(value) {
  if (document.querySelector('input[name="event[virtual]"]:checked').value=='true') {
    document.getElementById('virtual_true').style.display = 'none';
    let room = document.getElementById('event_venue_room');
    if (room.value == '') {
      room.value = 'Digital';
    }
  } else {
    document.getElementById('virtual_true').style.display = 'block';
  }
}

